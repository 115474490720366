import React from "react";
import PropTypes from "prop-types";

import MapOfBranches from "@components/maps/map-of-branches";

import "./outlets-on-map.scss";

/** Пункты продаж и пополнения на карте */
const OutletsOnMap = props => {
	const { pageContext, showMapControls } = props;
	const { defaultType, defaultCompany } = pageContext;

	return (
		<section className="full-width outlets-on-map">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2 className="refill__header">Пункты продаж и пополнения карт</h2>
					</div>
				</div>
			</div>
			<MapOfBranches
				defaultType={defaultType}
				defaultCompany={defaultCompany}
				showMapControls={showMapControls}
			/>
		</section>
	);
};

OutletsOnMap.propTypes = {
	pageContext: PropTypes.shape({
		defaultType: PropTypes.oneOf(["sale", "refill"]),
		defaultCompany: PropTypes.string
	}),
	showMapControls: PropTypes.bool
};

OutletsOnMap.defaultProps = {
	data: [],
	pageContext: {}
};

export default OutletsOnMap;
